import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointXl, sbkCoreBreakpointMd, sbkCoreBreakpointLg} from "@/tokens/variables";

const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);
const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
export const sbkNavFlyoutStyle = css`
    :host {
        --nav-flyout-list-width: calc((100% / 12) * 8);
        display: block;
    }

    .nav-flyout {
        display: none;
        position: absolute;
        left: 0;
        background-color: var(--sbkNavFlyoutColorBgColorDefault);
        padding-top: var(--sbkNavFlyoutSpacingViewportXlInsetTop);
        padding-bottom: var(--sbkNavFlyoutSpacingViewportXlInsetBottom);
        width: 100%;
        z-index: 10;

        @media all and (max-width: ${breakpointXl}) {
            left: unset;
            right: 0;
            width: var(--sbkNavFlyoutSizingLgFixedWidth);
            top: var(--sbkTopNavBarSizingLargeScreenFixedBarHeight);
            background-color: var(--sbkNavFlyoutColorBgColorOnScroll);
            padding: 0;
            height: calc(100vh - var(--sbkTopNavBarSizingLargeScreenFixedBarHeight));
            overflow-x: hidden;
            overflow-y: auto;
            border-top: var(--sbkNavFlyoutBorderTop);
        }

        @media all and (max-width: ${breakpointLg}) {
            width: var(--sbkNavFlyoutSizingMdFixedWidth);
        }

        @media all and (max-width: ${breakpointMd}) {
            width: 100%;
            border-top: none;
        }
    }

    .nav-flyout--muted {
        background-color: var(--sbkNavFlyoutColorBgColorMuted);
    }

    .nav-flyout.expanded.on-scroll {
        border-top: var(--sbkNavFlyoutBorderTop);
        background-color: var(--sbkNavFlyoutColorBgColorOnScroll);

        .nav-flyout__content-wrapper {
            justify-content: flex-start;
        }

        .nav-flyout__controls-back {
            margin-left: 0;
        }
    }

    .nav-flyout.expanded {
        display: flex;
        justify-content: space-around;
    }

    .nav-flyout__controls {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        @media all and (max-width: ${breakpointXl}) {
            justify-content: flex-start;
        }
    }

    .nav-flyout__controls-back {
        margin-left: calc((100% / 12) * 2);

        @media all and (max-width: ${breakpointXl}) {
            margin-left: 0;
            padding-bottom: var(--sbkNavFlyoutSpacingViewportSmGapContent)
        }
    }

    .nav-flyout__content-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: var(--sbkCoreContentAreaMaxWidth);
        justify-content: center;

        @media all and (max-width: ${breakpointXl}) {
            flex-direction: column;
            flex-wrap: unset;
            padding: var(--sbkNavFlyoutSpacingViewportMdInsetTop) var(--sbkNavFlyoutSpacingViewportMdInsetRight) var(--sbkNavFlyoutSpacingViewportMdInsetBottom) var(--sbkNavFlyoutSpacingViewportMdInsetLeft);
            justify-content: unset;
        }

        @media all and (max-width: ${breakpointMd}) {
            padding: var(--sbkNavFlyoutSpacingViewportSmInsetTop) var(--sbkNavFlyoutSpacingViewportSmInsetRight) var(--sbkNavFlyoutSpacingViewportSmInsetBottom) var(--sbkNavFlyoutSpacingViewportSmInsetLeft);
        }
    }

    .nav-flyout__list-wrapper {
        width: var(--nav-flyout-list-width);

        @media all and (max-width: ${breakpointXl}) {
            width: 100%;
        }
    }

    .hide {
        display: none
    }

    .nav-flyout-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        column-count: 2;
        column-gap: var(--sbkFlyoutListItemSpacingLargeScrGapColumn)
    }

    .nav-flyout__list-wrapper.secondLevel {
        display: none;
    }

    .nav-flyout__list-wrapper.secondLevel.expandedSecondLevel {
        display: block;
    }

    .nav-flyout__list-wrapper.thirdLevel {
        display: none;
    }

    .nav-flyout__list-wrapper.thirdLevel.expandedThirdLevel {
        display: block;
    }

    .nav-flyout__controls-close {
        @media all and (max-width: ${breakpointXl}) {
            display: none;
        }
    }

    .nav-flyout__controls.expandedSecondLevel {
        justify-content: space-between;
    }

    .nav-flyout__meta-wrapper {
        display: none;
    }

    .nav-flyout__icons-wrapper {
        @media all and (max-width: ${breakpointXl}) {
            padding-bottom: var(--sbkNavFlyoutSpacingViewportSmGapContent);
        }
    }

    .nav-flyout__cta-wrapper {
        @media all and (max-width: ${breakpointXl}) {
            padding-top: var(--sbkNavFlyoutSpacingViewportSmGapContentButton);
            padding-bottom: var(--sbkNavFlyoutSpacingViewportSmGapContentButton);
            display: flex;
            flex-direction: column;  
            gap: var(--sbkCoreSpacingXsBase);
            justify-content: center;
            align-items: center;
        }
    }

    .nav-flyout__meta-nav-wrapper {
        @media all and (max-width: ${breakpointXl}) {
            padding-bottom: var(--sbkNavFlyoutSpacingViewportMdInsetBottom);
        }

        @media all and (max-width: ${breakpointMd}) {
            padding-bottom: var(--sbkNavFlyoutSpacingViewportSmInsetBottom);
        }
    }

    .nav-flyout-backdrop {
        display: none;
    }

    .nav-flyout-backdrop.expanded {
        display: block;
        position: absolute;
        top: 102px;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9;
        background-color: var(--sbkNavFlyoutColorBackdrop);

        @media all and (max-width: ${breakpointXl}) {
            top: var(--sbkTopNavBarSizingSmallScreenFixedBarHeight)
        }
    }
`;
