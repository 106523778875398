import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);
export const sbkMainNavItemsStyle = css`

    :host {
        flex-grow: 2
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        height: 100%;
        align-items: baseline;

        @media all and (max-width: ${breakpointXl}) {
            gap: var(--sbkTopNavBarSpacingSmallScreenGap);
        }
    }
    
    .on-scroll {
        gap: var(--sbkTopNavBarSpacingLargeScreenGap);
        justify-content: unset;
    }
`;
